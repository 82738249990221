import React, { useContext } from 'react';
import './DataAgentConnectionModal.less';
import { Button, Modal, Select, Input, Form } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useClusterTenants } from 'hooks/cluster';
import { SessionContext } from 'auth/SessionProvider';
import { searchFilterOption } from 'utils';
import {
  useCreateDataAgentConnection,
  useUpdateDataAgentConnection,
} from 'hooks/dataAgent';

const { TextArea } = Input;

function DataAgentConnectionModal({
  instanceID,
  clusterName,
  showModal,
  closeModal,
  dataAgentID,
  name,
  tenantID,
  description,
}: {
  instanceID: string;
  clusterName: string;
  showModal: boolean;
  closeModal: () => void;
  dataAgentID?: number;
  name?: string;
  tenantID?: number;
  description?: string;
}) {
  const isEdit = !!(dataAgentID && name && tenantID);
  const [form] = Form.useForm();

  const dataAgentConnectionFormFieldNames = {
    name: 'name',
    tanantID: 'tenantID',
    description: 'description',
  };

  const intl = useIntl();

  const { user } = useContext(SessionContext);
  const { data: clusterTenants } = useClusterTenants({
    instanceID,
    userId: user.uuid,
    clusterName,
  });

  const {
    mutateAsync: mutateCreateDataAgentConnection,
    isLoading: isCreateDataAgentConnectionLoading,
  } = useCreateDataAgentConnection({ instanceID });

  const {
    mutateAsync: mutateUpdateDataAgentConnection,
    isLoading: isUpdateDataAgentConnectionLoading,
  } = useUpdateDataAgentConnection({ instanceID });

  const isSubmitButtonLoading =
    isCreateDataAgentConnectionLoading || isUpdateDataAgentConnectionLoading;

  async function handleFinish() {
    try {
      const {
        name,
        tenantID,
        description,
      }: {
        name: string;
        tenantID: number;
        description: string;
      } = await form.validateFields();

      isEdit && dataAgentID
        ? await mutateUpdateDataAgentConnection({ dataAgentID, description })
        : await mutateCreateDataAgentConnection({
            tenantID,
            name,
            description,
          });

      form.resetFields();
      closeModal();
    } catch (error) {
      console.log('Validate Failed:', error);
    }
  }

  return (
    <Modal
      visible={showModal}
      title={
        <FormattedMessage
          id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.title"
          values={{
            action: isEdit ? (
              <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.titleEdit" />
            ) : (
              <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.titleCreate" />
            ),
          }}
        />
      }
      onCancel={closeModal}
      centered
      className="data-agent-connection-modal__wrapper"
      footer={
        <div>
          <Button onClick={closeModal}>
            <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.action.cancel" />
          </Button>

          <Button
            form="dataAgentConnectionForm"
            htmlType="submit"
            type="primary"
            style={{ marginLeft: 8 }}
            loading={isSubmitButtonLoading}
          >
            {isEdit ? (
              <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.action.submitEdit" />
            ) : (
              <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.action.submit" />
            )}
          </Button>
        </div>
      }
    >
      <Form
        layout="vertical"
        id="dataAgentConnectionForm"
        name="dataAgentConnectionForm"
        form={form}
        initialValues={
          isEdit
            ? {
                name,
                tenantID,
                description,
              }
            : {}
        }
        onFinish={handleFinish}
      >
        <Form.Item
          name={dataAgentConnectionFormFieldNames.name}
          rules={[
            { min: 1 },
            { max: 254 },
            {
              validator(_, name) {
                if (name) {
                  if (/^[a-zA-Z_][a-zA-Z0-9_]*$/.test(name)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    intl.formatMessage({
                      id: 'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.field.name.invalidDataAgentName',
                    }),
                  );
                }
                return Promise.reject(
                  intl.formatMessage({
                    id: 'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.field.name.required',
                  }),
                );
              },
            },
          ]}
          label={
            <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.field.name.label" />
          }
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.field.name.placeholder',
            })}
            disabled={isEdit}
          />
        </Form.Item>
        <Form.Item
          name={dataAgentConnectionFormFieldNames.tanantID}
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.field.tenant.required" />
              ),
            },
          ]}
          label={
            <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.field.tenant.label" />
          }
        >
          <Select
            showSearch
            filterOption={searchFilterOption}
            placeholder={intl.formatMessage({
              id: 'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.field.tenant.placeholder',
            })}
            disabled={isEdit}
          >
            {clusterTenants?.map(tenant => (
              <Select.Option key={tenant.id} value={tenant.id}>
                {tenant.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={dataAgentConnectionFormFieldNames.description}
          label={
            <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.field.description.label" />
          }
        >
          <TextArea
            rows={4}
            placeholder={intl.formatMessage({
              id: 'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.field.description.placeholder',
            })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default DataAgentConnectionModal;
