import './SelfManagedClusterModal.less';

import { Form, Button, Col, Input, Row, Select, Modal } from 'antd';
import React, { useState, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InfoCircleFilled } from '@ant-design/icons';
import {
  selfManagedClusterNameRules,
  getClusterTypeIcons,
  selfManagedClusterNameMax,
} from 'utils/cluster';
import { Instance, SelfManagedClusterTypes, SubCluster } from 'types/cluster';
import { SessionContext } from 'auth/SessionProvider';
import {
  useClusterSizeTypes,
  useRequestCreateSubCluster,
  useUpdateSubCluster,
} from 'hooks/cluster';
import { ReactComponent as IncortaLogo } from 'images/incorta-blue-logo.svg';
// import { ReactComponent as ChidoriLogo } from 'images/chidori-icon.svg';
import { InstancePlatform } from 'utils/platforms';

export const selfManagedClusterModalFieldNames = {
  name: 'name',
  size: 'size',
  type: 'type',
} as const;

interface SelfManagedClusterModal {
  showModal: boolean;
  onCancel(): void;
  instance: Instance;
  subCluster?: SubCluster;
}

function SelfManagedClusterModal({
  showModal,
  onCancel,
  instance,
  subCluster,
}: SelfManagedClusterModal) {
  const { user } = useContext(SessionContext);
  const intl = useIntl();
  const [form] = Form.useForm<{
    [selfManagedClusterModalFieldNames.name]: string;
    [selfManagedClusterModalFieldNames.size]: number;
    [selfManagedClusterModalFieldNames.type]: SelfManagedClusterTypes;
  }>();
  const clusterName = instance.name;
  const selfManagedClusterNamePrefix = `${clusterName}-`;
  const selfManagedClusters = instance.subClusters;
  const isSelfManagedClustersHaveSqlx =
    selfManagedClusters?.findIndex(
      smc => smc.type === SelfManagedClusterTypes.sqlx,
    ) >= 0
      ? true
      : false;
  const isSelfManagedClustersHaveChidori =
    selfManagedClusters?.findIndex(
      smc => smc.type === SelfManagedClusterTypes.chidori,
    ) >= 0
      ? true
      : false;

  const { validateFields } = form;

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

  const {
    mutateAsync: requestCreateSubCluster,
    isLoading: createSubClusterLoading,
  } = useRequestCreateSubCluster({ refetchClusterList: true });
  const {
    mutateAsync: requestUpdateSubCluster,
    isLoading: updateSubClusterLoading,
  } = useUpdateSubCluster({ refetchClusterList: true });

  const { data: clusterSizesResult, isLoading: isClusterSizesResultLoading } =
    useClusterSizeTypes({
      currentPlatform: instance.platform as InstancePlatform,
      clusterCode: instance.k8sClusterCode,
    });
  const availableSizes = clusterSizesResult?.data.sizes;
  const size =
    availableSizes?.length === 0
      ? null
      : subCluster?.sizeID ?? availableSizes?.[0]?.id;

  const types = [
    {
      id: 0,
      label: 'Analytics',
      value: SelfManagedClusterTypes.analytics,
      icon: (
        <IncortaLogo className="self-managed-cluster-modal__type-select-item" />
      ),
    },
    {
      id: 1,
      label: 'Advanced SQL Warehouse',
      value: SelfManagedClusterTypes.sqlx,
      icon: (
        <i className="self-managed-cluster-modal__type-select-item sqlx">
          SQLX
        </i>
      ),
    },
    // {
    //   id: 2,
    //   label: 'Chidori',
    //   value: SelfManagedClusterTypes.chidori,
    //   icon: (
    //     <ChidoriLogo className="self-managed-cluster-modal__type-select-item" />
    //   ),
    // },
  ];

  function handleClosingModal() {
    form.resetFields();
    onCancel();
  }

  async function handleSubmit(values: any) {
    try {
      await validateFields();
      if (subCluster) {
        // update sub Cluster
        await requestUpdateSubCluster({
          userId: user.uuid,
          clusterName,
          data: {
            subCluster: subCluster.name,
            size: values.size,
          },
        });
      } else {
        // create new one
        await requestCreateSubCluster({
          userId: user.uuid,
          clusterName,
          data: {
            type: values.type,
            size: values.size,
            name: values.name,
            nodes: 1,
          },
        });
      }
      handleClosingModal();
    } catch {}
  }

  const loading = createSubClusterLoading || updateSubClusterLoading;

  const onValuesChange = async (changedValues: any, allValues: any) => {
    const { name, type, size } = form.getFieldsValue();
    setTimeout(() => {
      if (
        (subCluster && (!type || !size)) ||
        (!subCluster && (!name || !type || !size)) ||
        form.getFieldsError().some(field => field.errors.length)
      ) {
        setIsSubmitButtonDisabled(true);
      } else {
        setIsSubmitButtonDisabled(false);
      }
    }, 0); // Added timeout to wait for the validation function in rules to be applied and reflected in the form.getFieldsError()
  };

  return (
    <Modal
      title={`${subCluster ? 'Edit' : 'Create a'} Self-Managed Cluster`}
      visible={showModal}
      onCancel={handleClosingModal}
      footer={null}
      width={645}
      centered
      destroyOnClose
    >
      <div className="self-managed-cluster-modal modal-body">
        <p className="self-managed-cluster-modal__desc">
          <FormattedMessage id={'createNewSubClusterModal.description'} />
        </p>

        <Form
          form={form}
          layout="vertical"
          id="ChangeLoadersModal"
          onFinish={handleSubmit}
          onValuesChange={onValuesChange}
          initialValues={{
            [selfManagedClusterModalFieldNames.name]: subCluster?.name,
            [selfManagedClusterModalFieldNames.size]: subCluster?.sizeID,
            [selfManagedClusterModalFieldNames.type]: subCluster?.type,
          }}
        >
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name={selfManagedClusterModalFieldNames.name}
                className="self-managed-cluster-modal__name-field"
                label={
                  <FormattedMessage
                    id={'createNewSubClusterModal.nameFieldLabel'}
                  />
                }
                rules={
                  subCluster
                    ? []
                    : [
                        ...selfManagedClusterNameRules,
                        {
                          validator(_rule, value: string) {
                            const totalLength =
                              selfManagedClusterNamePrefix.length +
                              value.length;
                            if (totalLength > selfManagedClusterNameMax) {
                              return Promise.reject(
                                `Name should not exceed ${selfManagedClusterNameMax} characters`,
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        },
                      ]
                }
                extra={
                  <>
                    <InfoCircleFilled />{' '}
                    <FormattedMessage
                      id={
                        [
                          InstancePlatform.AZURE,
                          InstancePlatform.AZURE_SAAS,
                        ].includes(instance.platform as InstancePlatform)
                          ? 'createNewSubClusterModal.nameFieldHint.azure'
                          : 'createNewSubClusterModal.nameFieldHint.gc'
                      }
                    />
                  </>
                }
              >
                <Input
                  size="middle"
                  defaultValue={!!subCluster ? subCluster?.name : undefined}
                  disabled={!!subCluster}
                  prefix={selfManagedClusterNamePrefix}
                  placeholder={
                    !subCluster
                      ? intl.formatMessage({
                          id: 'createNewSubClusterModal.nameFieldPlaceholder',
                        })
                      : ''
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name={selfManagedClusterModalFieldNames.size}
                label={
                  <FormattedMessage id="createNewSubClusterModal.sizeFieldLabel" />
                }
                initialValue={size}
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage id="clusterForm.clusterSizeRequired" />
                    ),
                  },
                ]}
              >
                <Select
                  size="large"
                  showSearch
                  loading={availableSizes?.length === 0}
                  placeholder={intl.formatMessage({
                    id: 'createNewSubClusterModal.sizeFieldPlaceholder',
                  })}
                  disabled={isClusterSizesResultLoading}
                >
                  {availableSizes?.map(size => (
                    <Select.Option key={size.id} value={size.id}>
                      {React.createElement(
                        getClusterTypeIcons(size.name, 'fill'),
                        {
                          className: 'fill-icon',
                          style: { fontSize: 14, color: '#99A6B0' },
                        },
                      )}{' '}
                      {size.displayName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name={selfManagedClusterModalFieldNames.type}
                label={
                  <FormattedMessage id="createNewSubClusterModal.typeFieldLabel" />
                }
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage id="clusterForm.typeFieldError" />
                    ),
                  },
                ]}
              >
                <Select
                  size="large"
                  showSearch
                  loading={availableSizes?.length === 0}
                  placeholder={intl.formatMessage({
                    id: 'createNewSubClusterModal.typeFieldPlaceholder',
                  })}
                  className="self-managed-cluster-modal__type-select"
                  dropdownClassName="self-managed-cluster-modal__type-select-dropdown"
                  disabled={!!subCluster}
                >
                  {types?.map(type => {
                    let isDisabled = false;
                    let tooltip = '';
                    if (type.value === SelfManagedClusterTypes.sqlx) {
                      isDisabled =
                        isSelfManagedClustersHaveSqlx ||
                        !instance.isIncortaXEligible;
                      tooltip = isSelfManagedClustersHaveChidori
                        ? 'A SQLx is already created'
                        : 'Unlock Advanced SQL Interface: Upgrade your cluster today for access. Enhance your data capabilities now!';
                    } else if (type.value === SelfManagedClusterTypes.chidori) {
                      isDisabled = isSelfManagedClustersHaveChidori;
                    }

                    return (
                      <Select.Option
                        key={type.value}
                        value={type.value}
                        disabled={isDisabled}
                        title={tooltip}
                      >
                        {type.icon}
                        {type.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <div className="modal-body-footer">
            <Button
              htmlType="submit"
              type="primary"
              disabled={isSubmitButtonDisabled}
              loading={loading}
            >
              {subCluster ? (
                <FormattedMessage id={'createNewSubClusterModal.saveButton'} />
              ) : (
                <FormattedMessage
                  id={'createNewSubClusterModal.createButton'}
                />
              )}
            </Button>
            <Button onClick={handleClosingModal}>
              <FormattedMessage id={'createNewSubClusterModal.cancelButton'} />
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default SelfManagedClusterModal;
